import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesome component
import { faUser, faPhoneAlt, faBuilding, faFileAlt, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons'; // Import icons

const HowItWorks = () => {
  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-10 text-gray-800">How It Works</h2>

        {/* Step 1 */}
        <div className="flex flex-col items-center text-left mb-8">
          <div className="flex-shrink-0 text-blue-600 text-5xl mb-4">
            <FontAwesomeIcon icon={faUser} /> {/* Use FontAwesomeIcon component */}
          </div>
          <h3 className="text-2xl font-semibold mb-2 text-gray-800">Step 1: Fill in Your Details</h3>
          <p className="text-lg text-gray-600">
            First, fill out your personal details so we can reach out to you.
          </p>
        </div>

        {/* Step 2 */}
        <div className="flex flex-col items-center text-left mb-8">
          <div className="flex-shrink-0 text-blue-600 text-5xl mb-4">
            <FontAwesomeIcon icon={faPhoneAlt} />
          </div>
          <h3 className="text-2xl font-semibold mb-2 text-gray-800">Step 2: Schedule an Online Call</h3>
          <p className="text-lg text-gray-600">
            One of our agents will schedule an online call to understand your requirements and suggest matching properties.
          </p>
        </div>

        {/* Step 3 */}
        <div className="flex flex-col items-center text-left mb-8">
          <div className="flex-shrink-0 text-blue-600 text-5xl mb-4">
            <FontAwesomeIcon icon={faBuilding} />
          </div>
          <h3 className="text-2xl font-semibold mb-2 text-gray-800">Step 3: Site Visits or Virtual Follow-ups</h3>
          <p className="text-lg text-gray-600">
            If you're in the UAE, you can do site visits with the agent. If not, we can have follow-up calls to go over the properties you're interested in.
          </p>
        </div>

        {/* Step 4 */}
        <div className="flex flex-col items-center text-left mb-8">
          <div className="flex-shrink-0 text-blue-600 text-5xl mb-4">
            <FontAwesomeIcon icon={faFileAlt} />
          </div>
          <h3 className="text-2xl font-semibold mb-2 text-gray-800">Step 4: Finalize & Paperwork</h3>
          <p className="text-lg text-gray-600">
            Once you finalize a property, we take care of all the paperwork and formalities for you.
          </p>
        </div>

        {/* Step 5 */}
        <div className="flex flex-col items-center text-left mb-8">
          <div className="flex-shrink-0 text-blue-600 text-5xl mb-4">
            <FontAwesomeIcon icon={faMoneyBillWave} />
          </div>
          <h3 className="text-2xl font-semibold mb-2 text-gray-800">Step 5: Cashback Within 30 Days</h3>
          <p className="text-lg text-gray-600">
            Within 30 days of property registration, we credit your account with the cashback, so you can invest it in your interiors or anything you like!
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
