import React, { useState } from "react";
import PropertyTypes from "./PropertyTypes"; // Importing the new component
import HowItWorks from "./HowItWorks"; // Importing the How It Works section
import BlogSection from "./BlogSection"; // Importing the How It Works section
import Header from './shared/Header'; // Import Header
import Footer from './shared/Footer'; // Import Footer

// Helper function to convert number to words
const numberToWords = (num) => {
  const a = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const toWords = (n) => {
    if (n < 20) return a[n];
    if (n < 100) return b[Math.floor(n / 10)] + (n % 10 ? "-" + a[n % 10] : "");
    if (n < 1000)
      return (
        a[Math.floor(n / 100)] +
        " hundred" +
        (n % 100 ? " and " + toWords(n % 100) : "")
      );
    if (n < 1000000)
      return (
        toWords(Math.floor(n / 1000)) +
        " thousand" +
        (n % 1000 ? " " + toWords(n % 1000) : "")
      );
    if (n < 1000000000)
      return (
        toWords(Math.floor(n / 1000000)) +
        " million" +
        (n % 1000000 ? " " + toWords(n % 1000000) : "")
      );
    return "number too large"; // For anything above 999 million
  };

  if (num === 0) return "zero";
  if (num > 999999999) return "number too large"; // Handling very large numbers

  return toWords(num);
};

// Function to determine property type based on URL content
const determinePropertyTypeFromURL = (url) => {
  const typeAKeywords = ["Emaar", "Damac", "Sobha", "Erlington"];
  const typeBKeywords = ["Danube", "Dubai South", "RAK", "Dubai Properties"];

  // Simulate checking the URL content by searching for keywords
  const lowerCaseURL = url.toLowerCase();

  for (let keyword of typeAKeywords) {
    if (lowerCaseURL.includes(keyword.toLowerCase())) {
      return "A";
    }
  }

  for (let keyword of typeBKeywords) {
    if (lowerCaseURL.includes(keyword.toLowerCase())) {
      return "B";
    }
  }

  return ""; // Return an empty string if no match
};

function App() {
  const [propertyValue, setPropertyValue] = useState("");
  const [propertyType, setPropertyType] = useState(""); // Automatically determined from URL or manually selected
  const [propertyURL, setPropertyURL] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [thankYou, setThankYou] = useState(false);

  
  const calculateSavings = () => {
    if (!propertyValue || isNaN(propertyValue)) return 0;

    // Calculate savings based on the detected property type
    const savingsPercentage = propertyType === "A" ? 1.25 : 1.75;
    return ((savingsPercentage / 100) * propertyValue).toFixed(2);
  };

  // Handle URL change and automatically set the property type based on the URL content
  const handleURLChange = (e) => {
    const url = e.target.value;
    setPropertyURL(url);

    const detectedType = determinePropertyTypeFromURL(url);
    if (detectedType) {
      setPropertyType(detectedType); // Automatically set the property type
    } else {
      setPropertyType(""); // Reset property type if no valid URL is entered
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setThankYou(true);
    setTimeout(() => {
      setIsModalOpen(false); // Close modal after showing thank-you message
      setThankYou(false); // Reset thank-you message
    }, 3000); // Auto close the thank you message after 3 seconds
  };

  return (
    <div className="min-h-screen flex flex-col font-sans">
      {/* Header */}
      <Header/>

      {/* Main Section with Background Colors */}
      <main className="flex-1 bg-gradient-to-r from-blue-100 to-blue-300 py-16" id="calculator">
        <div className="container mx-auto text-center">
          <h2 className="text-5xl font-extrabold mb-8 text-gray-800 leading-snug">
            Secure Your Dream Property & Save Thousands!
          </h2>
          <p className="text-xl mb-10 text-gray-700">
            Get 25-50% cashback on brokerage fees! We give you back a portion of the brokerage that we receive from developers.
          </p>

          <div className="flex flex-col items-center justify-center mb-8">
            <div className="flex items-center justify-center bg-blue-600 text-white font-extrabold text-5xl rounded-full h-64 w-64 shadow-lg mb-4">
              2.5M AED
            </div>
            <p className="text-2xl text-gray-800 font-semibold text-center">
              saved for buyers till now!
            </p>
          </div>

          {/* Calculator Section */}
          <div className="bg-white shadow-2xl rounded-lg p-10 max-w-lg mx-auto border-t-8 border-blue-600">
            <h3 className="text-3xl font-semibold mb-6 text-gray-800">Calculate Your Savings</h3>

            <div className="mb-6">
              <label className="block text-left text-lg font-medium text-gray-700 mb-2">Enter Property URL:</label>
              <input
                type="text"
                value={propertyURL}
                onChange={handleURLChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter property URL"
              />
              {propertyURL && (
                <p className="text-left mt-2 text-gray-700">
                  Detected Property Type: <span className="font-semibold">{propertyType}</span>
                </p>
              )}
            </div>

            {!propertyURL && (
              <div className="mb-6">
                <label className="block text-left text-lg font-medium text-gray-700 mb-2">Select Property Type:</label>
                <select
                  value={propertyType}
                  onChange={(e) => setPropertyType(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">-- Select Property Type --</option>
                  <option value="A">Tier A (1.25% savings)</option>
                  <option value="B">Tier B (1.75% savings)</option>
                </select>
              </div>
            )}

            <div className="mb-6">
              <label className="block text-left text-lg font-medium text-gray-700 mb-2">Total Property Value (AED):</label>
              <input
                type="number"
                value={propertyValue}
                onChange={(e) => setPropertyValue(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter property value"
              />
              {propertyValue && !isNaN(propertyValue) && (
                <p className="text-left mt-2 text-gray-700">
                  <span className="font-semibold">In Words:</span> {Number(propertyValue).toLocaleString()} AED
                </p>
              )}
            </div>

            <div className="mt-8">
              <p className="text-2xl font-semibold text-gray-800">
                You will save: <span className="text-blue-600">AED {calculateSavings()}</span>
              </p>
            </div>

            <button
              className="mt-8 w-full bg-blue-600 hover:bg-blue-700 text-white text-lg font-semibold py-3 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => setIsModalOpen(true)}
            >
              Get in Touch with an Agent
            </button>
          </div>
        </div>
      </main>

      {/* Modal Popup for Contact Information */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-md w-full relative">
            {!thankYou ? (
              <>
                <h2 className="text-2xl font-semibold mb-4">Get in Touch with an Agent</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block text-left text-lg font-medium text-gray-700 mb-2">Name:</label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block text-left text-lg font-medium text-gray-700 mb-2">Email:</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label className="block text-left text-lg font-medium text-gray-700 mb-2">WhatsApp Number:</label>
                    <input
                      type="tel"
                      value={whatsapp}
                      onChange={(e) => setWhatsapp(e.target.value)}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full bg-blue-600 hover:bg-blue-700 text-white text-lg font-semibold py-3 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                </form>
              </>
            ) : (
              <div className="text-center">
                <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
                <p className="text-lg text-gray-700">
                  Our agent will get in touch with you within 12 hours.
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Property Types Section */}
      <PropertyTypes />

      {/* How It Works Section */}
      <HowItWorks />

      {/*Blog Section */}
      <BlogSection />

      {/* Footer */}
      <Footer/>

    </div>
  );
}

export default App;
