import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import dubai_1 from './assets/images/dubai-1.jpg';
import dubai_2 from './assets/images/dubai-2.jpg';
import dubai_3 from './assets/images/dubai-3.jpg';

const blogPosts = [
  {
    id: 1,
    title: '5 Tips to Save on Real Estate Brokerage Fees',
    description: 'Learn the best strategies to save on brokerage fees when buying property in the UAE.',
    image: dubai_1, // Replace with actual image URLs
    date: 'September 10, 2024'
  },
  {
    id: 2,
    title: 'How to Invest in Dubai Real Estate as an Expat',
    description: 'A comprehensive guide for expats looking to invest in Dubai’s thriving real estate market.',
    image: dubai_2,
    date: 'September 5, 2024'
  },
  {
    id: 3,
    title: 'The Benefits of Getting Cashback on Your Property Purchase',
    description: 'Learn how cashback on broker fees can help you save money when buying property.',
    image: dubai_3,
    date: 'August 30, 2024'
  }
];
const BlogSection = () => {
    return (
      <section className="py-16 bg-white">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-10 text-gray-800">Latest Blog Posts</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {blogPosts.map((post) => (
              <div key={post.id} className="bg-gray-100 rounded-lg shadow-md hover:shadow-lg transition-shadow">
                <div className="w-full h-64 overflow-hidden">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-2xl font-semibold mb-2 text-gray-800">{post.title}</h3>
                  <p className="text-sm text-gray-500 mb-4">{post.date}</p>
                  <p className="text-lg text-gray-600">{post.description}</p>
                  <Link to={`/blog/${post.id}`} className="text-blue-500 font-semibold mt-4 inline-block">
                    Read More &rarr;
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
export default BlogSection;
