import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-6 mt-10">
      <div className="container mx-auto text-center">
        <p>&copy; {new Date().getFullYear()} Dubai Property Masters. All rights reserved.</p>
        <nav className="mt-4">
          <ul className="flex justify-center space-x-6">
            <li>
              <a href="#about" className="hover:text-gray-400">About</a>
            </li>
            <li>
              <a href="#privacy" className="hover:text-gray-400">Privacy Policy</a>
            </li>
            <li>
              <a href="#terms" className="hover:text-gray-400">Terms of Service</a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
