import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png'; // Adjust the path based on where you save the image

const Header = () => {
    return (
      <header className="bg-[#1a1a2e] text-white py-2 sticky top-0 z-50"> {/* Sticky and top-0 for sticking to the top */}
        <div className="container mx-auto flex justify-between items-center px-6">
          <div className="flex items-center">
            {/* Display the new logo with text and increase its size */}
            <Link to="/">
              <img src={logo} alt="Dubai Property Masters Logo" className="h-20 w-auto" /> {/* Adjust the size here */}
            </Link>
          </div>
          <nav>
            <ul className="flex space-x-6">
              <li>
                <Link to="/" className="hover:text-gray-300 text-white">Home</Link>
              </li>
              <li>
                <Link to="/blog" className="hover:text-gray-300 text-white">Blog</Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-gray-300 text-white">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
};

export default Header;
