import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage'; // Import your HomePage component
import BlogDetail from './BlogDetail'; // Import the BlogDetail component

const App = () => {
  return (
    <Router>
      <Routes>
        {/* HomePage with Blog Section */}
        <Route path="/" element={<HomePage />} /> 

        {/* Blog Detail Page */}
        <Route path="/blog/:id" element={<BlogDetail />} />
      </Routes>
    </Router>
  );
};

export default App;
