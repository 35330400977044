import React from 'react';

const PropertyTypes = () => {
  const typeAProperties = ['Emaar', 'Damac', 'Sobha', 'Erlington'];
  const typeBProperties = ['Danube', 'Dubai South', 'RAK', 'Dubai Properties'];

  return (
    <section className="bg-white py-16">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-10 text-gray-800">
          Property Types
        </h2>

        {/* Type A Properties */}
        <div>
          <h3 className="text-2xl font-semibold mb-6 text-gray-700">Tier A Properties</h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            {typeAProperties.map((property, index) => (
              <div
                key={index}
                className="bg-blue-100 p-6 rounded-lg shadow-lg text-center text-gray-800 font-semibold text-lg"
              >
                {property}
              </div>
            ))}
          </div>
        </div>

        {/* Type B Properties */}
        <div className="mt-12">
          <h3 className="text-2xl font-semibold mb-6 text-gray-700">Tier B Properties</h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            {typeBProperties.map((property, index) => (
              <div
                key={index}
                className="bg-green-100 p-6 rounded-lg shadow-lg text-center text-gray-800 font-semibold text-lg"
              >
                {property}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertyTypes;
