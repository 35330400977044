import React from "react";
import { useParams } from "react-router-dom";
import Header from "./shared/Header"; // Import Header
import Footer from "./shared/Footer"; // Import Footer

import dubai_1 from "./assets/images/dubai-1.jpg";
import dubai_2 from "./assets/images/dubai-1.jpg";
import dubai_3 from "./assets/images/dubai-3.jpg";

const blogPosts = [
  {
    id: 1,
    title: "5 Tips to Save on Real Estate Brokerage Fees",
    description:
      "Learn the best strategies to save on brokerage fees when buying property in the UAE.",
    image: dubai_1,
    date: "September 10, 2024",
    content: `
      Buying property in the UAE is an exciting opportunity, but the fees can add up quickly. One of the most 
      significant costs can be the brokerage fees. However, there are ways to minimize these fees and save money. 
      Here are 5 tips to help you save on brokerage fees when buying property in the UAE... [expand with full content].
    `,
  },
  {
    id: 2,
    title: "How to Invest in Dubai Real Estate as an Expat",
    description:
      "Learn the best strategies for investing in Dubai’s booming real estate market as an expat.",
    image: dubai_2,
    date: "September 10, 2024",
    content: `
      <p>Dubai has become one of the most attractive cities in the world for property investment, especially for expats.
      With its futuristic skyline, high-quality infrastructure, and strategic location as a global hub, it offers numerous
      opportunities for investors looking to gain high returns. However, as an expat, there are several key factors to
      consider before diving into Dubai’s property market.</p> <br/>

      <strong>Why Invest in Dubai Real Estate?</strong><br/>
      <p>Dubai's real estate market has consistently proven to be one of the strongest in the world. Here are a few reasons 
      why investing in Dubai real estate makes sense:</p><br/>
      <ul class="ml-5 list-disc">
        <li><strong>Tax-Free Property Ownership</strong>: Dubai offers tax-free property ownership, which means no capital gains tax or property tax.</li><br/>
        <li><strong>High Rental Yields</strong>: The city is known for its high rental yields, often ranging between 5-8%, which is higher than many
        major cities globally.</li><br/>
        <li><strong>Stable Economy and Strong Infrastructure</strong>: Dubai's government continues to invest heavily in its infrastructure and business
        environment, ensuring a stable economy that attracts global investors and multinational corporations.</li><br/>
        <li><strong>Global Hub for Expats</strong>: With nearly 90% of the population being expatriates, Dubai offers a welcoming environment
        for foreigners to invest in property with relative ease.</li>
      </ul>
      <br/>
      <strong>Types of Properties Expats Can Buy</strong><br/>
      <p>As an expat in Dubai, you're allowed to purchase properties in designated <strong>freehold areas</strong>, which are regions where non-UAE nationals
      can own property outright. Some popular freehold areas include:</p><br/>
      <ul class="ml-5 list-disc">
        <li><strong>Downtown Dubai</strong>: Home to the iconic Burj Khalifa, this area offers luxury apartments, penthouses, and a vibrant lifestyle.</li><br/>
        <li><strong>Dubai Marina</strong>: Known for its waterfront properties, Dubai Marina is popular among expats seeking modern, high-rise living with
        scenic views of the Arabian Gulf.</li><br/>
        <li><strong>Palm Jumeirah</strong>: The famous palm-shaped island offers exclusive villas and luxury apartments, perfect for high-end investors.</li><br/>
        <li><strong>Jumeirah Lakes Towers (JLT)</strong>: A more affordable option, JLT provides modern apartments and commercial spaces with great rental returns.</li><br/>
      </ul>
      <br/>
      <strong>Final Thoughts</strong><br/>
      <p>Investing in Dubai real estate as an expat can be an excellent opportunity, whether you’re looking for a rental income or long-term capital
      appreciation. The key to success is thorough research, understanding your financial situation, and choosing the right property in a prime location.</p><br/>
    `,
  },

  {
    id: 3,
    title: "5 Tips to Save on Real Estate Brokerage Fees",
    description:
      "Learn the best strategies to save on brokerage fees when buying property in the UAE.",
    image: dubai_3,
    date: "September 10, 2024",
    content: `
      Buying property in the UAE is an exciting opportunity, but the fees can add up quickly. One of the most 
      significant costs can be the brokerage fees. However, there are ways to minimize these fees and save money. 
      Here are 5 tips to help you save on brokerage fees when buying property in the UAE... [expand with full content].
    `,
  },
  // Add other blog posts here...
];

const BlogDetail = () => {
  const { id } = useParams(); // Get the blog ID from the URL
  const post = blogPosts.find((post) => post.id === parseInt(id));

  if (!post) {
    return <p>Blog post not found</p>;
  }

  return (
    <div>
      <Header /> {/* Add Header */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <div className="mb-8 text-center">
            {/* Image with max-width for large screens and centered alignment */}
            <img
              src={post.image}
              alt={post.title}
              className="w-full max-w-5xl mx-auto h-auto rounded-lg mb-6 object-cover"
            />
            <h1 className="text-4xl font-bold text-gray-800 mb-4">
              {post.title}
            </h1>
            <p className="text-gray-500 text-md">{post.date}</p>
          </div>
          <div
            className="prose prose-2xl max-w-none text-gray-800 leading-relaxed text-xl"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </div>
      </section>
      <Footer /> {/* Add Footer */}
    </div>
  );
};

export default BlogDetail;
